/*
Purpose: list details of FREQUENCY with use datatable
Developer: BY M.KTH
Date:07/10/2024
Project: TCMS
*/
import React from 'react';
import { useState, useCallback, useEffect } from "react";
import { NavLink,useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { Button as Buttonmui } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { security_key, BASE_URL} from "../global";




const ListProduct = () => {
  
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleRowLeadViewClick = async(e) => {
   
        navigate(`/EditProduct`, { state: { pk_pp_key: `${e.pk_pp_key}` }});
   
    
 }

   // Sample data source
    
     //Api call start
     const [data, setItems] = useState([]);
    
     useEffect(() => {
         fetchData();
     }, []);

     const fetchData = async () => {
         try {
             const response = await axios.post(BASE_URL+'API/api_index.php', {
               security_key: security_key,
               mode:"getAllProductCentreWithoutstripe",
               fk_cent_key: sessionStorage.getItem('key')
             });
             if(response.data.success===true){
               setRecords(response.data.data);
               setItems(response.data.data);
               setLoading(false);

             }else{
               //alert message provide
               setLoading(false);

             }
             
             
         } catch (error) {
             console.error('Error fetching data:', error);
         }
     };

     //Api call End
     const [showDelete, setDeleteShow] = useState(false);
     const [data_delete, setDeleteLabelData] = useState([]);
     const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);
   
   
     const handleDeleteClick = (row) => {
      //console.log(row)
        if(row && row.count_enrol && row.count_enrol > 0){
          toast.error("Sorry, this product has been enroled by a student and cannot be deleted.", {
            autoClose: 3000, // 3 seconds in this example
          });
        }else if(row && row.count_deposit && row.count_deposit > 0){
          toast.error("Sorry, this product has been deposit by a student and cannot be deleted.", {
            autoClose: 3000, // 3 seconds in this example
          });
        }else{
            
              const updatedData = { ...row };
              setDeleteLabelData(updatedData);
          
              setDeleteShow(true);
        }
     };
   

// Sample columns configuration
const columns = [
 
{ 
  
  headerName: 'Product Name',
  field: "Product Name",
  headerClassName: "super-app-theme--header",
  minWidth: 200,
  //flex: 1,
  valueGetter: (params) => {
    return `${params.row.pp_name == null || params.row.pp_name == '' ? '-' : params.row.pp_name}`;
  },

 },
 { 
    
  headerName: 'Product Type',
  field: "Product Type",
  headerClassName: "super-app-theme--header",
  flex: 1,
  valueGetter: (params) => {
    return `${params.row.pt_name == null || params.row.pt_name == '' ? '-' : params.row.pt_name}`;
  },
},
 { 
  
  headerName: 'Frequency Name',
  field: "Frequency Name",
  headerClassName: "super-app-theme--header",
  //minWidth: 100,
  flex: 1,
  valueGetter: (params) => {
    return `${params.row.fm_name == null || params.row.fm_name == '' ? '-' : params.row.fm_name}`;
  },

 },
  { 
    
    headerName: 'No Of Session',
    field: "No Of Session",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.pp_count_session == null || params.row.pp_count_session == '' ? '-' : params.row.pp_count_session}`;
    },
  },
  { 
    
    headerName: 'No Of Free Session',
    field: "No Of Free Session",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.pp_free_session == null || params.row.pp_free_session == '' ? '-' : params.row.pp_free_session}`;
    },
  },
  { 
    
    headerName: 'No Of Chargeable Session',
    field: "No Of Chargeable Session",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.pp_chargeble_session == null || params.row.pp_chargeble_session == '' ? '-' : params.row.pp_chargeble_session}`;
    },
  },
  { 
    
    headerName: "Per Session Amount(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
    field: "Per Session Amount",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${(params.row.pp_amount == null || params.row.pp_amount == '') || parseFloat(params.row.pp_amount) <= 0 ? '-' : params.row.pp_amount}`;
    },
  },
  { 
    
    headerName: 'Total Amount',
    field: "Total Amount",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.pp_total_amount == null || params.row.pp_total_amount == '' ? '-' : params.row.pp_total_amount}`;
    },
  },
  { 
    headerName: 'Payment Type',
    field: "Payment Type",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.pp_pay_method == null || params.row.pp_pay_method == '' ? '-' : parseInt(params.row.pp_pay_method) === 1 ? 'Online' : 'Offline'}`;
    },
    
  },
  // { 
  //   headerName: 'Payment Price ID',
  //   field: "Payment Price ID",
  //   headerClassName: "super-app-theme--header",
  //   flex: 1,
  //   valueGetter: (params) => {
  //     return `${params.row.stp_price_id == null || params.row.stp_price_id == '' ? '-' : parseInt(params.row.pp_pay_method) === 1 ? params.row.stp_price_id : '-'}`;
  //   },
    
  // },
  // { 
  //   headerName: 'Payment Meter Name',
  //   field: "Payment Meter Name",
  //   headerClassName: "super-app-theme--header",
  //   flex: 1,
  //   valueGetter: (params) => {
  //     return `${params.row.stp_price_meter == null || params.row.stp_price_meter == '' ? '-' : parseInt(params.row.pp_pay_method) === 1 ? params.row.stp_price_meter : '-'}`;
  //   },
    
  // },
  { 
    headerName: 'Method of payment',
    field: "Method of payment",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.pp_method == null || params.row.pp_method == '' ? '-' : parseInt(params.row.pp_method) === 1 ? 'Block' : 'Session'}`;
    },
    
  },
  {
    field: "actions",
    type: "actions",
    headerName: "Action",
    headerClassName: "super-app-theme--header",
    width: 100,
    headerAlign:"left",
    renderCell: (params) => {
      const row = params.row;

      return (
        <div className='d-flex  pad-12'>
          
            <Buttonmui variant="outlined-red" onClick={() => handleDeleteClick(row)} className="filled-danger my-2">Delete </Buttonmui>
          
        </div>
      );
    }
  },
];

const [records, setRecords] = useState(data);
function onSubmitUserDelete() {
  setIsUserDeleteSubmitting(true);

  const responseuserdelete = axios.post(BASE_URL + "API/api_index.php", {

    security_key: security_key,
    fk_cent_key: sessionStorage.getItem('key'),
    mode: "DeleteProduct",
    pk_pp_key: data_delete.pk_pp_key,
  }).then((responseProductdelete) => {

    if (responseProductdelete.data.data == 1) {

      setDeleteShow(false)
      // Handle the successful response here
      toast.success("Success! The product has been deleted.", {
        autoClose: 3000, // 3 seconds in this example
      });

      fetchData();

      setIsUserDeleteSubmitting(false);

    } else {
      toast.error("Failure! Unable to delete product. Try again later", {
        autoClose: 3000, // 3 seconds in this example
      });
      setIsUserDeleteSubmitting(false);
    }


  })
    .catch((error) => {
      // Handle any errors that occurred during the request
      // toast.error("Failure! Unable to delete product. Try again later", {
      //   autoClose: 3000, // 3 seconds in this example
      // });
      setIsUserDeleteSubmitting(false);
    });
}


  return (

    <><ToastContainer position="top-center" />
     <Modal show={showDelete} variant="danger">

<Modal.Header>Delete Product</Modal.Header>

<Modal.Body>
  <p>Are you sure? You want to delete this product? </p>

  {/* <Card className='rounded-1 mt-2 card-bdr' >
    <Card.Body>

      <div>
        <div className="d-flex justify-content-between align-self-center">
          <div className="me-5">
            <div className="">


              <h6 className=''>Session Name:</h6>
              <p className="ml-3 mb-2">
                {data_delete.sest_name}
              </p>
              <h6>Day:</h6>
              <p className="ml-3 mb-2">
                {data_delete.sest_day}
              </p>
              <h6>Tutor Name</h6>
              <p className=" ml-3mb-2">
                {data_delete.tut_fname + ' ' + data_delete.tut_surname}
              </p>
              <h6>From Date:</h6>
              <p className=" ml-3 mb-2">
                {Moment(data_delete.sest_from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
              </p>
              <h6>End Date:</h6>
              <p className=" ml-3 mb-2">
                {Moment(data_delete.sest_to_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
              </p>


            </div>
          </div>
        </div>
        <span>

        </span>
      </div>

    </Card.Body>
  </Card>
  <hr /> */}
</Modal.Body>
<Modal.Footer className='pt-0'>
  <div className="mx-auto d-flex gap-2" >

    <Button variant="contained" className="primary-gray" onClick={() => setDeleteShow(false)}>Cancel</Button>
    <Button variant="contained" type='submit' className="primary-filled " disabled={isUserDeleteSubmitting} onClick={() => onSubmitUserDelete()} >Yes</Button>
  </div>

</Modal.Footer>

</Modal>

    <div className="page-wrapper">

        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
            <div>
                <h5 className='page-title'> Pricing</h5>
                
                <div role="presentation" >
                    <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                    <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                        <Typography color="text.primary">Pricing</Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <div className='d-flex align-items-center gap-2'>
                {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                <NavLink className="primary-filled rounded-10 p-2" to='/AddProduct' >
                <AddIcon className="btn-icon"/> New Product</NavLink>
            </div>
          </div>

        

              {/* --------------Section Start--------------- */}  

              <Card className='my-card p-1 bg-white'>

                  <Card.Body>
                          
                      {/* -------------Content Start--------------- */}  

                      <Row>
                        <Col xs="12">
                            <div className="">

                                <Row className="justify-content-center">

                                    
                                    


                                    {/* --------------Table Content Start--------------- */} 
                                    <Col sm={12}>

                                    <Box
                                            sx={{
                                                height: "fit-content",
                                                width: "100%",
                                                "& .actions": {
                                                    color: "text.secondary",
                                                },
                                                "& .textPrimary": {
                                                    color: "text.primary",
                                                },
                                                "& .super-app-theme--header": {
                                                    backgroundColor: "#e3e6ee",
                                                },
                                            }}
                                            style={{ height: '100%', width: "100%" }}
                                        >
                                           <DataGrid
                                                sx={{
                                                  // disable cell selection style
                                                  '.MuiDataGrid-cell:focus': {
                                                    outline: 'none'
                                                  },
                                                  // pointer cursor on ALL rows
                                                  '& .MuiDataGrid-row:hover': {
                                                    cursor: 'pointer'
                                                  }
                                                }}
                                                //sx={{ border: "none", m: 2 }}
                                                autoHeight
                                                slots={{
                                                    loadingOverlay: LinearProgress,
                                                    //noRowsOverlay: CustomNoRowsOverlay,
                                                    toolbar: GridToolbar,
                                                    // toolbar: EditToolbar,
                                                }}
                                                slotProps={{
                                                    toolbar: {
                                                        csvOptions: { fileName: "SubjectList" },
                                                        printOptions: { disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "SubjectList",pageStyle: `@media print {
                                                          @page {
                                                            size: A4 landscape;
                                                            margin: 0;
                                                          }
                                                        }` },
                                                        records,
                                                        showQuickFilter: true
                                                    },


                                                }}
                                                initialState={{
                                                    ...records.initialState,
                                                    pagination: { paginationModel: { pageSize: 10 } },
                                                }}
                                                showQuickFilter={true}

                                                pageSizeOptions={[10, 25, 50, 100]}

                                                loading={loading}
                                                rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                                columns={columns}
                                                rowHeight={60} density="compact"
                                                // onRowClick={(params) => handleRowLeadViewClick(params.row)}

                                                disableRowSelectionOnClick
                                                showCellVerticalBorder={true}
                                                showColumnVerticalBorder={true}

                                            />


                                        </Box>
                                      
                                         

                                    </Col>
                                    {/* --------------Table Content Start--------------- */} 

                                </Row>
                                
                            </div>
                        </Col>
                      </Row>

                      {/* --------------Content End--------------- */}


                  </Card.Body>

              </Card>

              {/* --------------Section End--------------- */}




        {/* --------------Page End--------------- */}

    </div>
    </>
);


};

export default ListProduct;
