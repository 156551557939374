/*
Purpose: View details of student
Designed: BY R.Sanjai
Date:22/08/2023 
Project: TCMS
*/

import { useNavigate, useLocation, NavLink } from "react-router-dom";


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import CSS styles for the phone input


import { FiEye, FiEyeOff } from "react-icons/fi"; // Assuming you've imported the eye icons

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { security_key, options_time_zone, options_countries, options_weeklydays,options_time_zone_identifier, BASE_URL } from "../global";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import { WithContext as ReactTags } from "react-tag-input";


function CentreEnrolFields() {

    const [fieldDetails, setFieldDetails] = useState([]);
    
      const initialSetCount = 1; // Set the initial number of sets
      // const [isSubmitting, setIsSubmitting] = useState(false);
    
    const initialDynamicFields = fieldDetails;
    
    const basicSchema = yup.object().shape({
        dynamicFields: yup.array().of(
              yup.object().shape({
                fieldType: yup.string().required("Field Type is required"),
                // questionOptions: yup.array().min(1, "At least one option is required"),
                questionOptions: yup
                .array()
                .when("fieldType", (fieldType, schema) => {
                    if (fieldType == "1" || fieldType == "5") {
                        return schema.notRequired(); // Not required when fieldType is "1"
                    }
                    return schema.min(1, "At least one option is required"); // Required otherwise
                }),
                question: yup
                  .string()
                  .required("Question is required")
                  .min(2, "Minimum 2 characters is required."),
              })
            ),
    });
    const location = useLocation();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);



    //const [id, setId] = useState('');

    useEffect(() => {
       
            fetchData();
      
    }, [location]);

    const [optionFieldType, setOptionFieldType] = useState([ 
        { "value": 1, "label": "Text" },
        { "value": 2, "label": "Option" },
        { "value": 3, "label": "Check Box" },
        { "value": 4, "label": "DropDown" },
        { "value": 5, "label": "Date" }
    ]);
    //const [optionQuestion, setOptionQuestion] = useState([]);
    //Api call start


    const fetchData = async () => {
        try {
          const response = await axios.post(BASE_URL + "API/api_index.php", {
            security_key: security_key,
            mode: "getCenterEnrolFields",
            fk_cent_key: sessionStorage.getItem('key'),
          });
    
          if (response.data.success === true) {
            if (response.data.data.length > 0) {
                setFieldDetails(response.data.data);
              // handleOption();
            } else {
                setFieldDetails([
                {
                  pk_me_key: null,
                  fieldType: "",
                  question: "",
                  questionOptions: []
                },
              ]);
            }
          } else {
            setFieldDetails([
              {
                pk_me_key: null,
                fieldType: "",
                question: "",
                questionOptions: []
              },
            ]);
    
            // setTestDetails([{
            //     fieldType: 'test1',
            //     mark: 20,
            //     comment: 'commend1',
            //   },{
            //     fieldType: 'test2',
            //     mark: 40,
            //     comment: 'commend2',
            //   }]);
            // Handle unsuccessful response, e.g., show an alert to the user.
          }
        } catch (error) {
          console.error("Error fetching data:", error);
    
          setFieldDetails([
            {
                pk_me_key: null,
                fieldType: "",
                question: "",
                questionOptions: []
            },
          ]);
        }
      };



    const onSubmit = async (formValues) => {
        // Disable the submit button and reset alerts
        setIsSubmitting(true);


            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    security_key: security_key,
                    mode: "createEnrolFields",
                    // fk_ci_id: sessionStorage.getItem('user_id'),
                    // user_type: sessionStorage.getItem('user_type'),
                    // cent_profile: formValues.cent_profile_pic,
                    ...formValues,
                });


                if (response.data.success === true) {
                    if (response.data.data === 1) {
                        //resetFormFields(resetForm, setValues, initialValues);
                        toast.success("Success! Your enrol fields has been updated successfully.", {
                            autoClose: 3000, // 3 seconds in this example
                        });
                      
                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            fetchData();
                        }, 2000); // 3000 milliseconds, which is equivalent to 3 seconds

                        setIsSubmitting(false); // Re-enable the submit button
                    } else {
                        toast.error("Failure! Unable to update enrol fields. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to update enrol fields. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to update enrol fields. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button

            } finally {
                setIsSubmitting(false); // Re-enable the submit button

            }

       // }
    };
    // });

    const formik =  useFormik({
        initialValues: {
            /*tbl_certre*/
            dynamicFields: initialDynamicFields,

            updated_by: sessionStorage.getItem('key'),
            fk_cent_key: sessionStorage.getItem('key')

        },
        enableReinitialize: true,
        validationSchema: basicSchema,
        onSubmit
    });

    //  const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
    // const formik = useFormik({

// Now, you can set variables as needed:
const values = formik.values;
const handleBlur = formik.handleBlur;
const touched = formik.touched;
const handleChange = formik.handleChange;
const errors = formik.errors;
const handleSubmit = formik.handleSubmit;
const setTouched = formik.setTouched;
const resetForm = formik.resetForm;
const setValues = formik.setValues;
const handleReset = formik.handleReset;
const fileInputRef = useRef(null);

    
console.log(values)
    
console.log(errors)
    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) { const firstErrorField = Object.keys(formik.errors)[0];
            //const firstErrorFieldId = `${firstErrorField}Id`;
          
            const errorFieldElement = document.getElementById(firstErrorField);
          
            if (errorFieldElement) {
               errorFieldElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
            } else {
             // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
         //document.getElementById(Object.keys(formik.errors)[0]).focus();
        }
      }, [formik]);

      const handleAddInput = () => {
        formik.setFieldValue("dynamicFields", [
          ...formik.values.dynamicFields,
          { 
            pk_me_key: null,
            fieldType: "",
            question: "",
            questionOptions: []
         },
        ]);
      };
    
      const handleRemoveInput = (index) => {
        const dynamicFields = [...formik.values.dynamicFields];
    
        if (dynamicFields.length !== initialSetCount) {
          dynamicFields.splice(index, 1);
          formik.setFieldValue("dynamicFields", dynamicFields);
        }
        //handleOption();
      };
    
      

// Handle updating tags in Formik
const updateTags = (fieldIndex, updatedTags) => {
    // console.log(fieldIndex)
    // console.log(updatedTags)
    const updatedFields = [...formik.values.dynamicFields];
    updatedFields[fieldIndex].questionOptions = updatedTags;
    formik.setFieldValue("dynamicFields", updatedFields);
};
// console.log([...formik.values.dynamicFields])

const [DynamicFieldArray, setUpdatedDynamicFieldArray] = useState([]);
console.log(DynamicFieldArray)
const handleDragEnd = async result => {
    if (!result.destination) return

    const updatedDynamic = Array.from(formik.values.dynamicFields)
    const [reorderedItem] = updatedDynamic.splice(result.source.index, 1)
    updatedDynamic.splice(result.destination.index, 0, reorderedItem)
    formik.setFieldValue("dynamicFields", updatedDynamic);
    //setLessonActivityArray(updatedActivities)
    setUpdatedDynamicFieldArray(updatedDynamic)
  }
  const SubmitOrderEnrolField = async (array) => {
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "orderByStudentEnrolFields",
        fk_cent_key: sessionStorage.getItem("key"),
        orderData: array,
      });

      if (response.data && response.data.data == 1) {
        console.log("Order Changed")
        // setReEnrollRecords(response.data.data);
        // toggleReenrol();
        // setModalShow2(false);
      }
    } catch (error) {
      console.error("Error order not chanhged:", error);
    }
  };
    useEffect(() => {
        const updatedDynamic = Array.from(DynamicFieldArray) || [];
        const filteredData = updatedDynamic.filter(item => item.pk_me_key && item.pk_me_key.trim() !== "").map(item => item.pk_me_key);
      if (Array.from(filteredData) && filteredData.length > 0) {
            SubmitOrderEnrolField(filteredData);
      }
    }, [DynamicFieldArray]);

    return (
        <>
             <ToastContainer position="top-center" />
            {/* center form */}
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                <div className="">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'> Enrol Settings </h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                    <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">Enrol Settings</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

                    </div>

                    {/* --------------Student Particulars--------------- */}
                    <Stack>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Enrol Settings </h6>
                            </div>

                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="lessonActivities">
                                    {provided => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >

                                        {/* Dynamic Fields */}
                                        {formik.values.dynamicFields.map((field, index) => (
                                             <Draggable
                                             //isDragDisabled={switch2}
                                             key={index}
                                             draggableId={index.toString()}
                                             index={index}
                                           >
                                             {provided => (
                                              
                                            <div key={index}  ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            // onClick={() =>
                                            //   setActivityNavSeleceted(index)
                                            // }
                                            >
                                            <Row className="ht-line mt-3">
                                                {" "} <div className="mb-2"><strong>Q.No :{ index+1}</strong></div>
                                                <Col lg="3" md="6" sm="12" className="position-relative">
                                                <h6 className="form-lable required-field">Field Type</h6>
                                                <Select
                                                    menuPlacement="auto"
                                                    options={optionFieldType}
                                                    name={`dynamicFields[${index}].fieldType`}
                                                    value={optionFieldType.find(
                                                    (option) =>
                                                        option.value &&
                                                        field.fieldType &&
                                                        option.value.toString() ===
                                                        field.fieldType.toString()
                                                    )}
                                                    id={`dynamicFields[${index}].fieldType`}
                                                    onChange={(selectedOption) => {
                                                    const event = {
                                                        target: {
                                                        name: `dynamicFields[${index}].fieldType`,
                                                        value: selectedOption["value"],
                                                        },
                                                    };
                                                    handleChange(event);
                                                    //handleOption();
                                                    }}
                                                    onBlur={(event) => {
                                                    handleBlur(event);
                                                    // setTouched({ ...touched, fk_sc_id: true });
                                                    }}
                                                    className={
                                                    formik.errors.dynamicFields &&
                                                    formik.errors.dynamicFields[index]?.fieldType &&
                                                    formik.touched.dynamicFields &&
                                                    formik.touched.dynamicFields[index]?.fieldType
                                                        ? "input-error"
                                                        : ""
                                                    }
                                                />
                                                {formik.errors.dynamicFields &&
                                                    formik.errors.dynamicFields[index]?.fieldType &&
                                                    formik.touched.dynamicFields &&
                                                    formik.touched.dynamicFields[index]?.fieldType && (
                                                    <p className="error">
                                                        {formik.errors.dynamicFields[index].fieldType}
                                                    </p>
                                                    )}
                                                </Col>
                                                <Col lg="5" md="6" sm="12" className="position-relative">
                                                <h6 className="form-lable required-field">Question</h6>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={1}
                                                    name={`dynamicFields[${index}].question`}
                                                    value={field.question}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    id={`dynamicFields[${index}].question`}
                                                    className={
                                                    formik.errors.dynamicFields &&
                                                    formik.errors.dynamicFields[index]?.question &&
                                                    formik.touched.dynamicFields &&
                                                    formik.touched.dynamicFields[index]?.question
                                                        ? "input-error control_textarea"
                                                        : "control_textarea"
                                                    }
                                                />
                                                {/* <Form.Control
                                                    type="text"
                                                    name={`dynamicFields[${index}].question`}
                                                    value={field.question}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    id={`dynamicFields[${index}].question`}
                                                    className={
                                                    formik.errors.dynamicFields &&
                                                    formik.errors.dynamicFields[index]?.question &&
                                                    formik.touched.dynamicFields &&
                                                    formik.touched.dynamicFields[index]?.question
                                                        ? "input-error"
                                                        : ""
                                                    }
                                                /> */}
                                                {formik.errors.dynamicFields &&
                                                    formik.errors.dynamicFields[index]?.question &&
                                                    formik.touched.dynamicFields &&
                                                    formik.touched.dynamicFields[index]?.question && (
                                                    <p className="error">
                                                        {formik.errors.dynamicFields[index].question}
                                                    </p>
                                                    )}
                                                </Col>
                                                <Col lg="3" md="6" sm="12" className="position-relative">
                                                {field.fieldType != 1 && field.fieldType != 5 ? //not input and date field
                                                <>
                                                    <h6 className="form-lable required-field">Options</h6>
                                                
                                                    <ReactTags
                                                        tags={formik.values.dynamicFields[index].questionOptions}
                                                        handleAddition={(tag) =>
                                                            updateTags(index, [...formik.values.dynamicFields[index].questionOptions, tag])
                                                        }
                                                        handleDelete={(tagIndex) =>
                                                            updateTags(
                                                                index,
                                                                formik.values.dynamicFields[index].questionOptions.filter((_, i) => i !== tagIndex)
                                                            )
                                                        }
                                                        handleDrag={(tag, currPos, newPos) => {
                                                            const tags = [...formik.values.dynamicFields[index].questionOptions];
                                                            tags.splice(currPos, 1);
                                                            tags.splice(newPos, 0, tag);
                                                            updateTags(index, tags);
                                                        }}
                                                        placeholder="Add a option"
                                                    />
                                                    {formik.touched.dynamicFields?.[index]?.questionOptions &&
                                                        formik.errors.dynamicFields?.[index]?.questionOptions && (
                                                            <p className="error">
                                                                {formik.errors.dynamicFields[index].questionOptions}
                                                            </p>
                                                        )}
                                            
                                                    {formik.errors.dynamicFields &&
                                                    formik.errors.dynamicFields[index]?.questionOptions &&
                                                    formik.touched.dynamicFields &&
                                                    formik.touched.dynamicFields[index]?.questionOptions && (
                                                    <div className="error">
                                                        {formik.errors.dynamicFields[index].questionOptions}
                                                    </div>
                                                    )}
                                                </>
                                                    : null}
                                                </Col>
                                                
                                            
                                                
                                                <Col
                                                lg="1"
                                                md="1"
                                                sm="1"
                                                className="position-relative mt-4"
                                                >
                                                <Button
                                                    type="button"
                                                    variant="outlined-red"
                                                    className="filled-danger my-2"
                                                    style={{
                                                    display:
                                                        formik.values.dynamicFields.length == 1
                                                        ? "none"
                                                        : "block",
                                                    }}
                                                    onClick={() => handleRemoveInput(index)}
                                                >
                                                    Delete
                                                </Button>

                                                {/* <button type="button" onClick={() => handleRemoveInput(index)}>
                                                Remove
                                                </button> */}
                                                </Col>
                                            </Row>
                                            </div>
                                             )}
                                            </Draggable>
                                        ))}
                                        {/* <button type="button" onClick={handleAddInput}>
                                            Add
                                        </button> */}
                                        <Button
                                            type="button"
                                            variant="contained"
                                            className="primary-filled mt-3 "
                                            onClick={handleAddInput}
                                        >
                                            Add
                                        </Button>

                                        {provided.placeholder}
                                    </div>
                                    )}
                                </Droppable>
                            </DragDropContext>

                        </div>
                    </Stack>
                    <Stack gap={2} className="mt-4">
                        <div className="text-end">
                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Update</Button>
                        </div>
                    </Stack>

                    {/* --------------dynamic fields Details start--------------- */}
                        {/* {formik.values.dynamicFields.length > 0 ?
                        <Stack className="mt-4">
                            <div className="my-card p-4 bg-white">
                                <div className='section-heading border-bottom pb-3 mb-3'>
                                    <h6 className='mb-0'>Request from the Centre (Preview)</h6>
                                </div>
                                
                                <div >
                                        <Row>
                                    {formik.values.dynamicFields.map((field, index) => (
                                        // <div key={index}>
                                        // <Row>
                                        <>
                                            {" "} 
                                            <Col lg="4" md="6" sm="12" className="position-relative mt-3" key={index}>
                                            <h6 className="form-lable">{field.question}</h6>
                                            {
                                                field.fieldType && field.fieldType == 1 ?
                                                <Form.Control
                                                    as="textarea"
                                                    rows={1}
                                                    name={`dynamicFields[${index}].answer`}
                                                    // value={field.answer}
                                                    // onChange={formik.handleChange}
                                                    // onBlur={formik.handleBlur}
                                                    // id={`dynamicFields[${index}].answer`}
                                                    className={
                                                   "control_textarea"
                                                    }
                                                />

                                                : field.fieldType && field.fieldType == 2 ?
                                                <div>{
                                                field.questionOptions.map((option, index2) => (
                                                    <label className="custom-option-align">
                                                        <input
                                                        type="radio"
                                                        value={option.text}
                                                        name={index2+'-'+option.label}
                                                        checked={index2 == 0}
                                                        // checked={currentView === 'dayGridMonth'}
                                                        // onChange={(e)=>{
                                                        //     console.log(e.target.value)
                                                        //     const updatedFields = [...formik.values.dynamicFields];
                                                        //     updatedFields[index].answer = e.target.value ;
                                                        //     formik.setFieldValue("dynamicFields", updatedFields);
                                                        // }}
                                                        />
                                                        {option.text}
                                                    </label>
                                                ))}
                                                </div>
                                                :

                                                field.fieldType && field.fieldType == 3 ?
                                                <div>{
                                                field.questionOptions.map((option, index3) => (
                                                    <label className="custom-option-align">
                                                        <input
                                                        type="checkbox"
                                                        value={option.text}
                                                        label={option.label}
                                                        name={index3 + '-' + option.label}
                                                        // onChange={(e) => {
                                                        // const value = e.target.value;
                                                        // const updatedFields = [...formik.values.dynamicFields];
                                                        // const currentAnswer = updatedFields[index].answer || '';
                                                
                                                        // // If the checkbox is checked, append the value; if unchecked, remove the value
                                                        // let newAnswer;
                                                        // if (e.target.checked) {
                                                        //     // Add the value if not already present
                                                        //     newAnswer = currentAnswer ? `${currentAnswer} | ${value}` : value;
                                                        // } else {
                                                        //     // Remove the value if unchecked
                                                        //     newAnswer = currentAnswer
                                                        //     .split(' | ')
                                                        //     .filter(opt => opt.trim().toLowerCase() !== value.trim().toLowerCase())
                                                        //     .join(' | ');
                                                        // }
                                                
                                                        // updatedFields[index].answer = newAnswer;
                                                        // formik.setFieldValue('dynamicFields', updatedFields);
                                                        // }}
                                                        checked={index3 == 0}
                                                        
                                                        />
                                                        {option.text}
                                                    </label>
                                                
                                                ))}
                                                </div>
                                                :
                                                field.fieldType && field.fieldType == 4 ?
                                                <div>{
                                                    <Select
                                                            menuPlacement="auto"
                                                            options={field.questionOptions}
                                                            name={`dynamicFields[${index}].answer`}
                                                            // value={field.questionOptions.find(
                                                            // (option) =>
                                                            //     option.value &&
                                                            //     field.answer &&
                                                            //     option.value.toString() ===
                                                            //     field.answer.toString()
                                                            // )}
                                                            // id={`dynamicFields[${index}].answer`}
                                                            // onChange={(selectedOption) => {
                                                            // const event = {
                                                            //     target: {
                                                            //     name: `dynamicFields[${index}].answer`,
                                                            //     value: selectedOption["value"],
                                                            //     },
                                                            // };
                                                            // handleChange(event);
                                                            // //handleOption();
                                                            // }}
                                                            // onBlur={(event) => {
                                                            // handleBlur(event);
                                                            // // setTouched({ ...touched, fk_sc_id: true });
                                                            // }}
                                                            // className={
                                                            // formik.errors.dynamicFields &&
                                                            // formik.errors.dynamicFields[index]?.answer &&
                                                            // formik.touched.dynamicFields &&
                                                            // formik.touched.dynamicFields[index]?.answer
                                                            //     ? "input-error"
                                                            //     : ""
                                                            // }
                                                        />
                                                    }
                                                </div>
                                                : null
                                            }
                                            
                                           
                                            </Col>
                                            </>
                                        // </Row>
                                        // </div>
                                    ))}
                                    </Row>
                                    </div>

                            </div>
                        </Stack>
                        : null} */}
                        {/* --------------dynamic fields Details end--------------- */}
                </div>
            </Form>
        </>
    );
}

export default CentreEnrolFields;